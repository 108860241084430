import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import Chambers from "../images/chambers.png"

const IndexPage = ({ data }) => (
  <Layout>
    <div className="w-full mx-auto  bg-gray-200 border-b border-gray-200">
      <div className="w-full lg:w-10/12 mx-auto">
        <Navigation />
      </div>
    </div>
    <div className="w-full lg:w-10/12 mx-auto">
      <div className="w-full lg:flex mx-auto mt-10 lg:mt-20">
        <div className="w-10/12 lg:w-1/4 text-center mx-auto">
          <img
            src={data.datoCmsCvSitiosPersonal.fotoDeLaPagina.url}
            alt="María Lourdes Echandi Gurdián"
            className="border border-gray-200 p-3 mt-3 shadow-md mx-auto"
          />

          <a
            href="https://chambers.com/lawyer/maria-lourdes-echandi-gurdian-latin-america-9:26062712"
            className="mt-3 mx-auto text-center"
            target="_blank"
            rel="nofollow"
            style={{ outline: "none" }}
          >
            <img src={Chambers} alt="Chambers" className="mx-auto mt-5" />
          </a>
        </div>
        <div
          className="w-full lg:w-3/4 px-10"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsCvSitiosPersonal.textoDeLaPagina,
          }}
        />
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query HomeQuery {
    datoCmsCvSitiosPersonal(id: { eq: "DatoCmsCvSitiosPersonal-7575419-en" }) {
      titulo
      fotoDeLaPagina {
        url
      }
      textoDeLaPagina
    }
  }
`
